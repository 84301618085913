
// Variables
@import "variables";



#contact {

	.input-group {
		width:100%;
		max-width: 450px;
		margin:0 auto 12px;
	}

	.contact-form {
		#contact-submit {
			background-color: #ffcc00;
			border-radius:0;
			margin: 15px auto;
			display: block;
			text-transform: uppercase;
		}
	}
}


.section {
	.section-heading {
		margin-bottom: 	40px;
		.subtile {
			font-size:21px;
		}
	}

	.lg-padding {
		padding:60px 0;
	}
}



/* ===========
Footer 
============ */ 

.footer {
	background-color:$brand-dark-b;
	color:#ffffff;
	padding:25px 0; 

	.menu {
		margin-bottom: 10px;
		margin-left: 0;
		> li {
			a {
				color:$white;
			}
			padding:0 18px 10px 0;
		}

	}

	.logo, .copyright {
		display: inline-block;
	}

	.logo {
		margin-right: 5px;
	}
}

@media screen and ( min-width:769px) {
	footer {

		.align_bottom {
			display: table;
			.copyright, .address  {
				display: table-cell;
				vertical-align: bottom;
				width:100%;
				padding-left: 10px;
			}
		}
		.align_bottom.text-right {
			height:30px;
		} 
	}
	h2 {
		font-size: 42px;
	}
}