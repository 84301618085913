//== Colors
$brand-info: #5bc0de !default;
$brand-light-b :#00ccff; 
$brand-light-y : #FFCC00;
$brand-dark-b: #222233; 
$light-bg : #F3F4F5;
$main-text : #333344;
$green: #01bb34;
$white: #ffffff;
$aqua-green : #4A94A5;


/*
Fonts
*/
$heading: 'Cabin', sans-serif;
$main-font: 'Open Sans', sans-serif;

	
//** Background color for `<body>`.
$body-bg: #fff !default;


/*Background url */

$base-url: "https://cdn.service.elive.co.nz/images";
